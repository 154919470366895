export const IndexFieldControlType = {
    TextBox: 1,
    ComboBox: 2,
    DateTimePicker: 3,
    BlankField: 4,
    List: 5,
    Number: 6,
    URL: 7,
    Email: 8,
    Checkboxes: 9,
    RadioButtons: 10,
    Currency: 11,
    Signature: 12,
    TextArea: 13,
    Address: 14
}