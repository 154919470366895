<template>
  <div class="vap-page">
    <Loading
      :active.sync="isLoading"
      :is-full-page="fullPage"
      :color="loaderColor"
      loader="dots"
      :background-color="loaderBackgroundColor"
    />
    <div class="vap-page-header">
      <VasionButton
        id="btnBack"
        title="Back"
        :isDense="true"
        :icon="'arrow_back'"
        @vasionButtonClicked="backToReportList(false)"
      />
      <h1>{{ headerText }}</h1>
      <div class="btns-container">
        <VasionButton id="btnCreateReport" :classProp="'primary'" @vasionButtonClicked="saveReport()">
          {{ saveButtonText }}
        </VasionButton>
      </div>
    </div>
    <div class="vap-page-content">
      <div class="md-layout">
        <div class="md-layout-item padding-right">
          <VasionInput
            id="report-name"
            v-model="reportName"
            class="input-style"
            inputType="top-white"
            title="REPORT NAME"
            required
            @input="isDirty = true"
          />
        </div>
        <div class="md-layout-item padding-right">
          <VasionDropList
            v-slot="slotItem"
            v-model="selectedIndexForm"
            :dataArray="indexForms"
            width="100%"
            :title="`${$formsLabel.toUpperCase()}`"
            type="plain-list"
            displayName="name"
            valueName="value"
            @input="isDirty = true"
          >
            {{ slotItem.item.name }}
          </VasionDropList>
        </div>
      </div>
      <div class="md-layout w100">
        <VasionButton id="btnCriteria" :classProp="reportingCriteriaTabClass" @vasionButtonClicked="updateSelectedTab('criteria')">
          Reporting Criteria
        </VasionButton>
        <VasionButton id="btnDisplayFields" :classProp="displayFieldsTabClass" @vasionButtonClicked="updateSelectedTab('display')">
          Display Fields
        </VasionButton>
        <VasionButton id="btnOrderByFields" :classProp="orderByTabClass" @vasionButtonClicked="updateSelectedTab('order')">
          Order By Fields
        </VasionButton>
        <VasionButton id="btnFolders" :classProp="folderTabClass" @vasionButtonClicked="updateSelectedTab('folder')">
          Folders
        </VasionButton>
        <VasionButton id="btnSecurity" :classProp="securityTabClass" @vasionButtonClicked="updateSelectedTab('security')">
          Security
        </VasionButton>
      </div>
      <hr class="tabDivider">
      <div class="w100">
        <div v-if="selectedTabName === 'criteria'" id="tabReportingCriteria">
          <div class="innerTabDiv">
            <table class="w100">
              <thead>
                <tr>
                  <th>
                    <label class="vasion-page-subheader">Field</label>
                  </th>
                  <th>
                    <label class="vasion-page-subheader">Comparison</label>
                  </th>
                  <th>
                    <label class="vasion-page-subheader">Value</label>
                  </th>
                  <th>
                    <label class="vasion-page-subheader">Actions</label>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in reportingCriteria" :key="`${index}${reportingCriteriaRefreshKey}`">
                  <td class="padding-right">
                    <VasionDropList
                      v-slot="slotItem"
                      v-model="item.SelectedField"
                      :dataArray="criteriaIndexFields"
                      theID="attribute-field"
                      width="100%"
                      title=""
                      type="plain-list"
                      displayName="name"
                      valueName="value"
                      @input="isDirty = true"
                    >
                      {{ slotItem.item.name }}
                    </VasionDropList>
                  </td>
                  <td class="padding-right">
                    <VasionDropList
                      v-if="item.FieldType && item.FieldType === 3"
                      v-slot="slotItem"
                      v-model="item.SelectedComparison"
                      class="drop-list-z"
                      :dataArray="dateComparisonCriteria"
                      width="100%"
                      title=""
                      type="plain-list"
                      displayName="value"
                      valueName="value"
                      @input="isDirty = true"
                    >
                      {{ slotItem.item.value }}
                    </VasionDropList>
                    <VasionDropList
                      v-if="canShowOtherComparisonDropList(item.FieldType)"
                      v-slot="slotItem"
                      v-model="item.SelectedComparison"
                      class="drop-list-z"
                      :dataArray="otherComparisonCriteria"
                      width="100%"
                      title=""
                      type="plain-list"
                      displayName="value"
                      valueName="value"
                      @input="isDirty = true"
                    >
                      {{ slotItem.item.value }}
                    </VasionDropList>
                  </td>
                  <td>
                    <VasionInput
                      v-if="item.CanEditValue"
                      v-model="item.FieldValue"
                      class="input-style"
                      inputType="top-white"
                      title=""
                      @input="isDirty = true"
                    />
                  </td>
                  <td>
                    <div class="md-layout">
                      <div>
                        <VasionButton
                          title="Remove Field"
                          :isDense="true"
                          :icon="'delete'"
                          @vasionButtonClicked="setDeleteItem(item)"
                        />
                      </div>
                      <div>
                        <VasionButton
                          v-show="shouldDisplayReorder(item, true)"
                          title="Move Up"
                          :isDense="true"
                          :icon="'arrow_upward'"
                          @vasionButtonClicked="moveCriteriaField(item, true)"
                        />
                      </div>
                      <div>
                        <VasionButton
                          v-show="shouldDisplayReorder(item, false)"
                          title="Move Down"
                          :isDense="true"
                          :icon="'arrow_downward'"
                          @vasionButtonClicked="moveCriteriaField(item, false)"
                        />
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <VasionButton id="btnAddCriteria" :classProp="'secondary'" @vasionButtonClicked="addCriteria">
              Add Field
            </VasionButton>
          </div>
        </div>

        <div v-if="selectedTabName === 'display'" id="tabDisplayFields">
          <div class="innerTabDiv">
            <div class="app-no-margin mt-1">
              <div class="col-md-6">
                <VasionListSelection
                  :showFilter="false"
                  class="fields"
                  :available-fields.sync="deselectedDisplayFields"
                  :selected-fields.sync="selectedDisplayFields"
                  :unique-id="'display-fields'"
                  :sortAlphabeticallyAtStart="false"
                  @update:availableFields="isDirty = true"
                  @update:selectedFields="isDirty = true"
                  @update:addAll="isDirty = true"
                  @update:removeAll="isDirty = true"
                />
              </div>
            </div>
          </div>
        </div>

        <div v-if="selectedTabName === 'order'" id="tabOrderByFields">
          <div class="innerTabDiv">
            <div class="app-no-margin mt-1">
              <div class="col-md-6">
                <VasionListSelection
                  :showFilter="false"
                  class="fields"
                  :available-fields.sync="deselectedOrderFields"
                  :selected-fields.sync="selectedOrderFields"
                  :unique-id="'order-fields'"
                  :sortAlphabeticallyAtStart="false"
                  @update:availableFields="isDirty = true"
                  @update:selectedFields="isDirty = true"
                  @update:addAll="isDirty = true"
                  @update:removeAll="isDirty = true"
                />
              </div>
            </div>
          </div>
        </div>

        <div v-if="selectedTabName === 'folder'" id="tabFolders">
          <div class="md-layout-item folders-btns">
            <VasionCheckbox
              id="search-in-subfolders"
              name="search-in-subfolders"
              class="subfolder-check"
              :checked="searchInSubfolders"
              @change="toggleSearchInSubfolders(); isDirty = true"
            >
              Search in Subfolders
            </VasionCheckbox>
            <VasionButton :classProp="'secondary-grey'" :isDisabled="deleteFolderDisabled" @vasionButtonClicked="deleteSelectedFolders">
              Delete Folder
            </VasionButton>
            <VasionButton :classProp="'secondary-grey'" @vasionButtonClicked="toggleBrowseFolderDialog">
              Add Folder
            </VasionButton>
          </div>
          <div v-if="tableData && tableData.Rows && tableData.Rows.Values.length > 0" class="innerTabDiv folders-div">
            <div class="app-no-margin mt-1">
              <div class="folders-table-container vasion-html-table-default">
                <VasionTable
                  id="folder-table"
                  :headerColumns="columns"
                  :tableRows="tableData.Rows.Values"
                  :hideColumns="hiddenColumns"
                  :supportSorting="true"
                  @vasion-row-checked="updateDeleteFolderDisable"
                />
              </div>
            </div>
          </div>
        </div>

        <div v-if="selectedTabName === 'security'" id="tabReportSecurity" title="Report Security">
          <div class="innerTabDiv">
            <div>
              <VasionListSelection
                :available-fields.sync="availableUsers"
                :selected-fields.sync="accessUsers"
                :allow-field-ordering="false"
                :available-label-text="'Restricted Users'"
                :selected-label-text="'Access Users'"
                :unique-id="'access-users'"
                @update:availableFields="isDirty = true"
                @update:selectedFields="isDirty = true"
                @update:addAll="isDirty = true"
                @update:removeAll="isDirty = true"
              />
            </div>
            <div class="separator-div" />
            <div>
              <VasionListSelection
                :available-fields.sync="availableGroups"
                :selected-fields.sync="accessGroups"
                :allow-field-ordering="false"
                :available-label-text="'Restricted Groups'"
                :selected-label-text="'Access Groups'"
                :unique-id="'access-groups'"
                @update:availableFields="isDirty = true"
                @update:selectedFields="isDirty = true"
                @update:addAll="isDirty = true"
                @update:removeAll="isDirty = true"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <md-dialog id="browseFoldersModal" :md-active.sync="showDialog" :md-click-outside-to-close="false">
      <div class="folderDialogBrowseDiv">
        <VasionFolders :includeWFQueue="true" />
      </div>
      <div class="alignRight">
        <VasionButton
          id="btnFolderBrowserCancel"
          classProp="secondary"
          :isRaised="false"
          @vasionButtonClicked="toggleBrowseFolderDialog()"
        >
          Cancel
        </VasionButton>
        <VasionButton
          id="btnFolderBrowserOK"
          classProp="primary"
          :isRaised="false"
          @vasionButtonClicked="folderModalOK()"
        >
          OK
        </VasionButton>
      </div>
    </md-dialog>

    <md-dialog id="confirmFormDelete" :md-active.sync="showDeleteDialog" :md-click-outside-to-close="false">
      <VasionConfirmationDialog :message="deletePromptMessage" @noButtonClick="toggleDeleteDialog()" @yesButtonClick="removeCriteriaField()" />
    </md-dialog>

    <md-dialog id="confirmLeave" :md-active.sync="showLeaveDialog" @md-clicked-outside="clickedOutsideLeave()">
      <VasionConfirmationDialog
        message="Are you sure you want to leave? Changes have not been saved."
        @noButtonClick="cancelLeave()"
        @yesButtonClick="doLeave()"
      />
    </md-dialog>

    <VasionSnackbar
      id="results-snack"
      :showSnackbarBool.sync="showSnackbar"
      :snackbarImage="snackbarImage"
      :snackbarSubTitle="snackbarText"
      :snackbarTitle="snackbarTitle"
    />
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';

// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
import { loaderBackgroundColor, loaderColor } from '@/assets/js/styleConfig'
import { IndexFieldControlType } from '../../enums/indexFieldControlType'
import { uuid } from 'vue-uuid';

export default {
  name: 'TheReportConfig',
  components: {
    Loading,
  },
  beforeRouteLeave(to, from, next) {
    if (this.isDirty && !this.routeTo) {
      this.routeTo = to
      this.toggleLeaveDialog()
    } else {
      this.routeTo = null
      next()
    }
  },
  data: function () {
    return {
      accessGroups: [],
      accessUsers: [],
      availableGroups: [],
      availableUsers: [],
      columns: [
        '_VasionCheckBox_',
        '_VasionIcon_',
        '_FolderId_',
        'Name',
      ],
      criteriaIndexFields: [{
        name: '-OR-',
        value: '-OR-',
        type: 0,
        dropdownValues: [],
      }],
      dateComparisonCriteria: [
        {
          value: '-None-',
        },
        {
          value: 'Equals',
        },
        {
          value: 'Less Than',
        },
        {
          value: 'Less Than Or Equal To',
        },
        {
          value: 'Greater Than',
        },
        {
          value: 'Greater Than Or Equal To',
        },
        {
          value: 'Is Empty',
        },
        {
          value: 'Not Empty',
        },
      ],
      deleteFolderDisabled: true,
      deleteFolderList: [],
      deleteItem: {},
      deleteItemName: '',
      deselectedDisplayFields: [],
      deselectedFolders: [],
      deselectedOrderFields: [],
      folderIDs: [],
      fullPage: true,
      hiddenColumns: ['_FolderId_'],
      isDirty: false,
      isLoading: false,
      loaderBackgroundColor,
      loaderColor,
      otherComparisonCriteria: [
        {
          value: '-None-',
        },
        {
          value: 'Equals',
        },
        {
          value: 'Starts With',
        },
        {
          value: 'Ends With',
        },
        {
          value: 'Contains',
        },
        {
          value: 'Is Empty',
        },
        {
          value: 'Not Empty',
        },
      ],
      reportID: 0,
      reportingCriteria: [],
      reportingCriteriaRefreshKey: uuid.v1().toString(),
      reportName: '',
      searchInSubfolders: false,
      selectedDisplayFields: [],
      selectedFolders: [],
      selectedIndexForm: {
        name: '',
        value: 0,
      },
      selectedOrderFields: [],
      showDeleteDialog: false,
      showDialog: false,
      showLeaveDialog: false,
      showSnackbar: false,
      snackbarImage: false,
      snackbarText: '',
      snackbarTitle: '',
      tableData: {
        Rows: {
          Values: [],
        },
      },
    }
  },
  computed: {
    allowRemoveCriteriaField() { return this.reportingCriteria && this.reportingCriteria.length > 1 },
    canAccessCustomReports() { return this.$store.getters['common/canAccessCustomReports'] },
    deletePromptMessage() { return `Are you sure you want to delete field "${this.deleteItemName}"?` },
    displayFieldsTabClass() { return this.selectedTabName === 'display' ? 'tab-selected' : 'tab' },
    folderTabClass() { return this.selectedTabName === 'folder' ? 'tab-selected' : 'tab' },
    groups() { return this.$store.state.common.groups },
    headerText() {
      if (this.selectedReport && this.reportID > 0) {
        return 'Modify Report'
      }

      return 'New Report'
    },
    indexFields() { return this.$store.state.common.indexFields },
    indexForms() {
      return this.$store.state.attributeForm.forms && this.$store.state.attributeForm.forms.length > 0
        ? this.$store.state.attributeForm.forms
        : [{ name: '', value: 0 }]
    },
    orderByTabClass() { return this.selectedTabName === 'order' ? 'tab-selected' : 'tab' },
    reportingCriteriaTabClass() { return this.selectedTabName === 'criteria' ? 'tab-selected' : 'tab' },
    saveButtonText() {
      if (this.selectedReport && this.reportID > 0) {
        return 'Update Report'
      }

      return 'Create Report'
    },
    securityTabClass() { return this.selectedTabName === 'security' ? 'tab-selected' : 'tab' },
    selectedFolder() { return this.$store.state.common.selectedFolder },
    selectedReport() { return this.$store.state.reporting.selectedCustomReportConfig },
    selectedTabName() { return this.$store.state.reporting.selectedTabName },
    users() { return this.$store.state.common.users },
  },
  watch: {
    reportingCriteria: {
      handler: function () {
        if (!this.reportingCriteria) {
          return
        }
   
        this.reportingCriteria.forEach((element) => {
          let fieldType = 0
          if (element.SelectedField && element.SelectedField.value) {
            element.FieldName = element.SelectedField.value
            fieldType = element.SelectedField.type
          }

          if (element.FieldType !== fieldType && element.FieldType > 0) {
            element.Comparison = ''
            element.SelectedComparison = {
              value: '',
            }
            element.FieldValue = ''
            this.updateReportingCriteriaRefreshKey()
          } else {
            element.Comparison = element.SelectedComparison.value
          }

          element.FieldType = fieldType
          element.CanEditValue = this.canEditValue(element.Comparison)
        })
      },
      deep: true,
    },
    selectedIndexForm: async function () {
      await this.$store.dispatch('common/getIndexFieldsForForm', this.selectedIndexForm ? this.selectedIndexForm.value : 0)
      this.updateSelectedFields()
    },
    selectedReport: function () { this.resetLocalValues() },
  },
  created: async function () {
    this.isLoading = true

    this.updateSelectedTab('criteria')

    const promise1 = await this.$store.dispatch('common/getUsers')
    const promise2 = await this.$store.dispatch('common/getGroups')
    const promise3 = await this.$store.dispatch('attributeForm/getForms')
    const promise4 = await this.$store.dispatch('common/setSelectedFolder', null)

    await Promise.all([promise1, promise2, promise3, promise4])

    this.resetLocalValues()
    this.isLoading = false
  },
  methods: {
    updateReportingCriteriaRefreshKey() {
      this.reportingCriteriaRefreshKey = uuid.v1().toString()
    },
    addCriteria() {
      this.reportingCriteria.push({
        FieldName: '-OR-',
        Comparison: '',
        FieldValue: '',
        AndGroupID: 0,
        FieldType: 0,
        CanEditValue: false,
        SelectedField: {
          name: '-OR-',
          value: '-OR-',
          type: 0,
          dropdownValues: [],
        },
        SelectedComparison: {
          value: '',
        },
      })
    },
    async backToReportList(reloadData) {
      if (reloadData) {
        await this.$store.dispatch('reporting/loadReports')

        this.$store.dispatch('reporting/setSelectedRunCustomReport', this.reportName)
        if (this.$store.state.reporting.customReports) {
          const reportIndex = this.$store.state.reporting.customReports.findIndex((r) => r.sName === this.reportName)
          if (reportIndex >= 0) {
            // this selects the correct report on the main nav
            this.$store.dispatch('mainViews/changeMainNav', `report-custom-${reportIndex}`)
          }
        }
      }

      this.isLoading = false
      if (reloadData === false && this.reportID <= 0) {
        this.$router.push({ name: 'TheEmptyReport' })
      } else {
        this.$router.push({ name: 'TheCustomReport' })
      }
    },
    canEditValue(comparisonString) {
      let ret = false
      if (comparisonString) {
        ret = (comparisonString !== 'Is Empty')
          && (comparisonString !== 'Not Empty')
          && (comparisonString !== '-None-')
      }

      return ret
    },
    cancelLeave() {
      this.routeTo = null
      this.toggleLeaveDialog()
    },
    canShowOtherComparisonDropList(fieldType) { 
      const comparisonFields = [ 
        IndexFieldControlType.TextBox,
        IndexFieldControlType.ComboBox,
        IndexFieldControlType.URL,
        IndexFieldControlType.Email,
        IndexFieldControlType.Currency,
        IndexFieldControlType.Signature,
        IndexFieldControlType.TextArea,
        IndexFieldControlType.Address,
      ]
      return comparisonFields.includes(fieldType)  
    },
    deleteSelectedFolders() {
      const newTableData = []
      this.tableData.Rows.Values.forEach(folder => {
        if (!folder.Values[0].value) {
          newTableData.push(folder)
        }
      })
      this.tableData.Rows.Values = newTableData
    },
    async doLeave() {
      this.toggleLeaveDialog()
      this.$router.push({ name: this.routeTo.name })
    },
    folderModalOK() {
      if (this.selectedFolder?.isSelected && this.tableData.Rows.Values.length > 0) {
        this.tableData.Rows.Values.every(folder => { // Checks if the selected folder has already been added
          if (!folder?.Values.includes(this.selectedFolder.item.FolderId)) {
            this.tableData.Rows.Values.push({
              Values: [
                {
                  show: true,
                  value: false,
                },
                'VasionFolderIcon',
                this.selectedFolder.item.FolderId,
                this.selectedFolder.item.Name,
              ],
            })
          } else {
            this.showSnackbar = true
            this.snackbarTitle = 'Duplicate Folder'
            this.snackbarText = 'That folder has already been added to the report.'
            this.snackbarImage = false
          }
          return false // Prevents a bug which adds the same entry multiple times
        })
      } else {
        this.tableData.Rows.Values.push({
          Values: [
            {
              show: true,
              value: false,
            },
            'VasionFolderIcon',
            this.selectedFolder.item.FolderId,
            this.selectedFolder.item.Name,
          ],
        })
      }

      this.toggleBrowseFolderDialog()
    },
    moveCriteriaField(criteria, moveUp) {
      if (!this.shouldDisplayReorder(criteria, moveUp)) {
        return;
      }
      const index = this.reportingCriteria.findIndex((item) => item === criteria);
      const newIndex = moveUp === true ? (index - 1) : (index + 1);

      const newArray = this.reportingCriteria.filter((element) => {
        return element !== criteria
      })

      newArray.splice(newIndex, 0, criteria)
      this.reportingCriteria = newArray;
    },
    removeCriteriaField() {
      this.reportingCriteria = this.reportingCriteria.filter((element) => {
        return element !== this.deleteItem
      })
      this.toggleDeleteDialog()
    },
    resetLocalValues() {
      this.reportID = this.selectedReport ? this.selectedReport.ReportID : 0
      this.reportName = this.selectedReport ? this.selectedReport.ReportName : ''

      if (this.selectedReport) {
        this.selectedIndexForm = this.indexForms.find((form) => {
          return form.value === this.selectedReport.IndexFormID
        })

        if (this.selectedReport.FolderIDs?.length > 0) {
          this.selectedReport.FolderIDs.forEach(async folderId => {
            const folderData = await this.$store.dispatch('vault/getSingleVault', folderId)
            this.tableData.Rows.Values.push({
              Values: [
                {
                  show: true,
                  value: false,
                },
                'VasionFolderIcon',
                folderId,
                folderData.vaultName,
              ],
            })
          })
        }
      }

      if (!this.selectedIndexForm) {
        // eslint-disable-next-line
        this.selectedIndexForm = this.indexForms[0]
      }

      this.searchInSubfolders = this.selectedReport ? this.selectedReport.SearchSubfolders : false

      if (this.selectedReport) {
        this.accessUsers = []
        this.accessGroups = []
        this.availableUsers = []
        this.availableGroups = []

        this.groups.forEach((group) => {
          const newGroup = {
            name: group.name,
            value: group.value,
          }

          if (this.selectedReport.AccessGroupIDs.includes(group.value)) {
            this.accessGroups.push(newGroup)
          } else {
            this.availableGroups.push(newGroup)
          }
        })

        this.users.forEach((user) => {
          const newUser = {
            name: user.name,
            value: user.value,
          }

          if (this.selectedReport.AccessUserIDs.includes(user.value)) {
            this.accessUsers.push(newUser)
          } else {
            this.availableUsers.push(newUser)
          }
        })
      } else {
        this.accessUsers = []
        this.accessGroups = []

        this.availableUsers = this.users.map((user) => {
          return {
            name: user.name,
            value: user.value,
          }
        })

        this.availableGroups = this.groups.map((group) => {
          return {
            name: group.name,
            value: group.value,
          }
        })
      }
    },
    async saveReport() {
      // make sure the user entered everything correctly
      if (!this.validateData()) {
        return
      }

      this.isLoading = true
      if (this.tableData.Rows.Values.length > 0) {
        this.folderIDs = this.tableData.Rows.Values.map(folder => {
          return folder.Values[2]
        })
      } else {
        this.folderIDs = []
      }

      // everything except for the SearchFields
      const reportPayload = {
        ReportID: this.reportID,
        FolderID: null,
        FolderIDs: this.folderIDs,
        SearchSubfolders: this.searchInSubfolders,
        IndexFormID: this.selectedIndexForm ? this.selectedIndexForm.value : 0,
        ReportName: this.reportName,
        AccessUserIDs: this.accessUsers ? this.accessUsers.map((user) => { return user.value }).filter(e => e != null) : [],
        AccessGroupIDs: this.accessGroups ? this.accessGroups.map((group) => { return group.value }).filter(e => e != null) : [],
        OrderedDisplayFields: this.selectedDisplayFields.map((field) => field.value),
        OrderedOrderbyFields: this.selectedOrderFields.map((field) => field.value),
        SearchFields: [],
      }

      // build up the search fields
      let andGroupID = 1
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < this.reportingCriteria.length; i++) {
        const criteria = this.reportingCriteria[i]
        if ((!criteria.FieldName || criteria.FieldName === '-OR-')) {
          if (i !== 0) {
            andGroupID += 1
          }
        } else {
          reportPayload.SearchFields.push({
            FieldName: criteria.FieldName,
            Comparison: !criteria.Comparison || criteria.Comparison === '-None-' ? '' : criteria.Comparison,
            FieldValue: criteria.CanEditValue ? criteria.FieldValue : '',
            AndGroupID: andGroupID,
          })
        }
      }

      const response = await this.$store.dispatch('reporting/saveCustomReport', reportPayload)
      if (!response || !response.data || !response.data.ReportID || response.data.ReportID <= 0) {
        this.isLoading = false
        this.snackbarTitle = 'ERROR'
        this.snackbarText = 'An unexpected error occurred while saving the Report.'
        this.snackbarImage = false
        this.showSnackbar = true
        return
      }

      await this.$store.dispatch('reporting/replaceCustomReport', response.data)

      this.isLoading = false
      this.snackbarTitle = 'Success'
      this.snackbarImage = true
      if (this.selectedReport && this.reportID > 0) {
        this.snackbarText = 'Report saved successfully!'
      } else {
        this.snackbarText = 'Report created successfully!'
      }
      this.showSnackbar = true

      this.isDirty = false
      setTimeout(() => {
        this.backToReportList(this.canAccessCustomReports)
      }, 1500)
    },
    setDeleteItem(criteria) {
      this.deleteItem = criteria
      this.deleteItemName = criteria.SelectedField.name
      this.toggleDeleteDialog()
    },
    shouldDisplayReorder(criteria, moveUp) {
      const index = this.reportingCriteria.findIndex((item) => item === criteria);
      const arrayLength = this.reportingCriteria.length;

      return !(index < 0
        || (moveUp === true && index === 0)
        || (moveUp === false && index === (arrayLength - 1)))
    },
    toggleBrowseFolderDialog() { this.showDialog = !this.showDialog },
    toggleDeleteDialog() { this.showDeleteDialog = !this.showDeleteDialog },
    toggleLeaveDialog() { this.showLeaveDialog = !this.showLeaveDialog },
    toggleSearchInSubfolders() { this.searchInSubfolders = !this.searchInSubfolders },
    updateDeleteFolderDisable(event) {
      this.deleteFolderDisabled = event.length === 0
      this.deleteFolderList = event
    },
    updateSelectedFields() {
      const newArray = this.indexFields ? this.indexFields.slice(0) : []
      const orElement = {
        name: '-OR-',
        value: '-OR-',
        type: 0,
        dropdownValues: [],
      }

      newArray.splice(0, 0, orElement)

      this.criteriaIndexFields = newArray
      this.reportingCriteria = []

      if (newArray.length > 1) {
        this.addCriteria()
      }

      this.selectedDisplayFields = []
      this.deselectedDisplayFields = this.indexFields ? JSON.parse(JSON.stringify(this.indexFields)) : []

      this.selectedOrderFields = []
      this.deselectedOrderFields = this.indexFields ? JSON.parse(JSON.stringify(this.indexFields)) : []

      if (!this.selectedReport || !this.selectedIndexForm || this.selectedIndexForm.value !== this.selectedReport.IndexFormID) {
        return
      }

      // we're modifying a report and the selected form id matches that report
      // so, use the default fields for the report
      // first, do the Display Fields
      this.selectedReport.OrderedDisplayFields.forEach((selectedFieldName) => {
        const foundItem = this.deselectedDisplayFields.find((fieldElement) => {
          return fieldElement.value === selectedFieldName;
        })

        if (foundItem) {
          this.selectedDisplayFields.push(foundItem);
          this.deselectedDisplayFields = this.deselectedDisplayFields.filter((deselectedElement) => {
            return deselectedElement.value !== selectedFieldName
          })
        }
      })

      // next, do the Order By Fields
      this.selectedReport.OrderedOrderByFields.forEach((selectedFieldName) => {
        const foundItem = this.deselectedOrderFields.find((fieldElement) => {
          return fieldElement.value === selectedFieldName;
        })

        if (foundItem) {
          this.selectedOrderFields.push(foundItem);
          this.deselectedOrderFields = this.deselectedOrderFields.filter((deselectedElement) => {
            return deselectedElement.value !== selectedFieldName
          })
        }
      })

      // now, do the reporting criteria
      const localArray = []
      let groupID = -1
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < this.selectedReport.SearchFields.length; i++) {
        if (this.selectedReport.SearchFields[i].AndGroupID !== groupID) {
          // it's a new grouping, so add the OR row in
          if (groupID > 0) {
            localArray.push({
              FieldName: '-OR-',
              Comparison: '',
              FieldValue: '',
              AndGroupID: 0,
              FieldType: 0,
              CanEditValue: false,
              SelectedField: orElement,
              SelectedComparison: {
                value: '',
              },
            })
          }
          groupID = this.selectedReport.SearchFields[i].AndGroupID
        }

        const selectedField = this.criteriaIndexFields.find((field) => {
          return field.value === this.selectedReport.SearchFields[i].FieldName
        })

        const tempElement = {
          FieldName: this.selectedReport.SearchFields[i].FieldName,
          Comparison: this.selectedReport.SearchFields[i].Comparison,
          FieldValue: this.selectedReport.SearchFields[i].FieldValue,
          AndGroupID: groupID,
          FieldType: selectedField.type,
          SelectedField: selectedField,
          SelectedComparison: {
            value: this.selectedReport.SearchFields[i].Comparison,
          },
        }

        localArray.push(tempElement)
      }

      this.reportingCriteria = localArray.slice(0)
    },
    updateSelectedTab(newSelectedTab) {
      this.$store.dispatch('reporting/setSelectedTabName', newSelectedTab)
    },
    validateData() {
      let valid = true
      let errorText = ''

      if (!this.reportName) {
        errorText += '- Please enter a Report Name.\n'
        this.valid = false
      }
      if (this.reportName.trim() === '') {
        errorText += '- The Report Name cannot only include spaces.\n'
        valid = false
      }

      if (!this.selectedIndexForm || this.selectedIndexForm.value <= 0) {
        errorText += `- Please select ${$formsLabelWithPrefix}.\n`
        valid = false
      }

      if (!this.reportingCriteria || this.reportingCriteria.length <= 0) {
        errorText += '- Please enter at least one Reporting Criteria.\n'
        valid = false
      }

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < this.reportingCriteria.length; i++) {
        const element = this.reportingCriteria[i]
        if (element.FieldName && element.FieldName !== '-OR-') {
          if (!element.Comparison || element.Comparison === '' || element.Comparison === '-None-') {
            errorText += `- Missing Comparison for Reporting Criteria #${(i + 1)}.\n`
            valid = false
          }

          if (element.CanEditValue && (!element.FieldValue || element.FieldValue === '')) {
            errorText += `- Missing Value for Reporting Criteria #${(i + 1)}.\n`
            valid = false
          }
        }
      }

      if (!this.selectedDisplayFields || this.selectedDisplayFields.length <= 0) {
        errorText += '- Please select at least one Display Field.\n'
        valid = false
      }

      if (!valid) {
        this.snackbarTitle = 'ERROR'
        this.snackbarText = errorText
        this.snackbarImage = false
        this.showSnackbar = true
        return false
      }
      return true
    },
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';

  .subfolder-check {
    padding: 15px 0;
    width: 175px;
    margin-left: calc(100% - 487px);
    margin-right: 15px;
  }

  .folders-btns {
    width: 100%;
    display: flex;
  }

  .folders-div {
    overflow-y: auto;

    div {
      width: 100%;
      overflow: auto;
    }
  }

  hr {
    border-width: 1px;
    border-color: $grey-100;
  }

  .tabDivider {
    border-style: solid;
    margin-top: 0;
  }

  .folderDialogBrowseDiv {
    width: 400px;
    height: 300px;
    overflow: auto;
  }

  .w100 {
    width: 100%;
  }

  .alignRight {
    text-align: right;
    display: block;
    width: 100%;
  }

  .padding-right {
      padding-right: 8px;
  }

  input:disabled+label {
    color: #ccc;
  }

  .innerTabDiv {
    padding: 5px;
  }

  .separator-div {
    height: 40px;
  }

  .fields{
    width: calc(100vw - 425px);
    height: 425px;
  }

  @media(max-width: $breakpoint-md){
    .fields{
      width: calc(100vw - 72px);
    }
  }

  tr {
    height: 50px;
  }
  .folders-table-container {
    height: calc(100vh - 345px);
  }
</style>
